<template>
  <div class="wallet-box">
    <el-dialog :modal-append-to-body="false" v-if="$store.state.userInfo" :visible.sync="$store.state.isWallet"
      custom-class="el-dialog-transparent el-dialog-center" @close="closeWallet" center :close-on-click-modal="true"
      z-index="2999">

      <div class="wallet_container">
        <div class="tabs-wapper">
          <div class="tabs-content">
            <el-button :class="['tabs-btn', tabsCur == 0 ? 'tabs-btn-active' : '']" @click="changeTabs(0)">
              <div class="btn-content">
                {{ $t("充值") }}
              </div>
            </el-button>
            <el-button :class="['tabs-btn', tabsCur == 1 ? 'tabs-btn-active' : '']" @click="changeTabs(1)">
              <div class="btn-content">
                {{ $t("提现") }}
              </div>
            </el-button>
          </div>

        </div>

        <div class="wallet-wapper" ref="wallet_wapper">

          <i class="el-icon-error el-icon" @click="hideSync"></i>

          <div class="wallet_left">
            <div class="method_wrap" :class="{ method_Mb_hidden: rechargeSteps == 1 }" v-if="bankAll && tabsCur == 0">
              <div @click="selectMethod(item)" v-for="(item, index) in bankAll.pay_types" :key="index"
                :class="{ method_item_active: methodId == item.id }" class="method_item">
                <img v-if="item.is_hot" class="icon_hot" src="@/assets/images/icon_channel_hot.png" alt="">
                <img :src="item.icon" alt="">
                {{ item.name }}

              </div>
            </div>

            <div v-if="bankAll && tabsCur == 1">
              <!-- 移动端切换 -->
              <swiper class="pc-hide method_wrap_m" ref="swiperRef" :options="{
                effect: 'coverflow', slidesPerView: 'auto', spaceBetween: 30,
                centeredSlides: true, coverflowEffect: { rotate: 0, stretch: -20, depth: 300, modifier: 1, },
                on: { slideChange }
              }">
                <swiper-slide v-for="(item) in wtAllUsdt" :key="item.id" style="width: 324px;">
                  <div :class="{ bank_item_active: bankId == item.id }" class="bank_item">
                    <div class="bank_list">
                      <span class="bank-item-title">{{ item.bank_name }}</span>
                      <span class="bank-item-sub-title">{{ $t("钱包地址") }}</span>
                      <span class="bank-item-num">{{ item.bank_number.slice(0, 4) }} **** **** **** {{
                        item.bank_number.slice(-4) }}</span>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide v-for="(item) in wtAllBank" :key="item.id" style="width: 324px;">
                  <div :class="{ bank_item_active: bankId == item.id }" class="bank_item">
                    <div class="bank_list">
                      <span class="bank-item-title">{{ item.bank_name }}</span>
                      <span class="bank-item-sub-title">{{ $t("银行卡号码") }}</span>
                      <span class="bank-item-num">{{ item.bank_number.slice(0, 4) }} **** **** **** {{
                        item.bank_number.slice(-4) }}</span>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <!-- pc端 -->
              <div class="method_wrap m-hide" v-if="(wtAllUsdt.length != 0 || wtAllBank.length != 0)">
                <div @click="secondStepOfLegalTenderWithdrawal(item)" v-for="(item) in wtAllUsdt" :key="item.id"
                  :class="{ bank_item_active: bankId == item.id }" class="bank_item">
                  <div class="bank_list">
                    <span class="bank-item-title">{{ item.bank_name }}</span>
                    <span class="bank-item-sub-title">{{ $t("钱包地址") }}</span>
                    <span class="bank-item-num">{{ item.bank_number.slice(0, 4) }} **** **** **** {{ item.bank_number.slice(-4)
                      }}</span>
                  </div>
                </div>
                <div @click="secondStepOfLegalTenderWithdrawal(item)" v-for="(item) in wtAllBank" :key="item.id"
                  :class="{ bank_item_active: bankId == item.id }" class="bank_item">
                  <div class="bank_list">
                    <span class="bank-item-title">{{ item.bank_name }}</span>
                    <span class="bank-item-sub-title">{{ $t("银行卡号码") }}</span>
                    <span class="bank-item-num">{{ item.bank_number.slice(0, 4) }} **** **** **** {{ item.bank_number.slice(-4)
                      }}</span>
                  </div>
                </div>

              </div>

              <div class="nope_bank" v-else>
                <img src="@/assets/images/nope_bank.png" alt="">
                <p>{{ $t('未检测到帐户') }}</p>
              </div>
              <div class="set_btn_wrap">
                <el-button class="set_btn button_red" @click="setting">
                  <div class="btn-content">
                    <span>{{ $t("去添加") }}</span>
                  </div>
                </el-button>
              </div>

            </div>


          </div>
          <div class="wallet_right" ref="wallet_right">
            <rechargeTwo v-if="tabsCur == 0" ref="rechargeTwo" :hide="hide" @back="back" :bankAll="bankAll || {}"
              :channelList="channelList" @custom-event="handleCustomEvent" @nextStep="nextStep"></rechargeTwo>

            <withdrawalTwo :ver_withdrawal_first="ver_withdrawal_first" :pay_passwd_verify="pay_passwd_verify"
              :withdrawalRules="withdrawalRules" :bankAll="bankAll || {}" :userWithdrawalAmount="userWithdrawalAmount"
              :wtRnum="wtRnum" :wtMinimum="wtMinimum" :wtHighest="wtHighest" @submit="ConfirmWithdrawal"
              :selectWithdrawalChannels="selectWithdrawalChannels" v-if="tabsCur == 1 && $store.state.isWallet"
              @back="back" :hide="hide">
            </withdrawalTwo>
          </div>
          <!-- <div class="tabs2-wapper">
            <div :class="['tabs2-item ', tabsCur2 == 1 ? 'tabs2-active' : '']" @click="changeTabs2(1)">{{ $t("法定货币") }}
            </div>
            <div :class="['tabs2-item ', tabsCur2 == 0 ? 'tabs2-active' : '']" @click="changeTabs2(0)">{{ $t("数字钱包") }}
            </div>
          </div> -->

          <!-- <el-skeleton :rows="9" animated v-if="isLoading" /> -->

          <div v-if="false">

            <!-- 数字货币充值 -->


            <!-- 法定货币充值 -->
            <legalCurrencyRecharge @setting="setting" @submit="secondStepOfLegalTenderRecharge"
              v-if="tabsCur == 0 && tabsCur2 == 1" :allBank="allBank" :bankAll="bankAll || {}"
              @parent-event="parentEvent">
            </legalCurrencyRecharge>

            <!-- 数字货币提现 -->
            <digitalCurrencyWthdrawal @setting="setting" @submit="secondStepOfLegalTenderWithdrawal"
              :wtAllUsdt="wtAllUsdt" v-if="tabsCur == 1 && tabsCur2 == 0" @parent-event="parentEvent">
            </digitalCurrencyWthdrawal>

            <!-- 法定货币提现 -->
            <legalCurrencyWithdrawal @setting="setting" @submit="secondStepOfLegalTenderWithdrawal"
              v-if="tabsCur == 1 && tabsCur2 == 1" :wtAllBank="wtAllBank" @parent-event="parentEvent">
            </legalCurrencyWithdrawal>
          </div>

        </div>
        <!-- 充值第二步 -->
        <!-- <rechargeTwo ref="rechargeTwo" :hide="hide" @back="back" v-if="rechargeSteps == 1" :bankAll="bankAll || {}"
          :selectRechargeChannel="selectRechargeChannel" @custom-event="handleCustomEvent"></rechargeTwo> -->

        <!-- 提现第二步 -->
        <withdrawalTwo :ver_withdrawal_first="ver_withdrawal_first" :pay_passwd_verify="pay_passwd_verify"
          :withdrawalRules="withdrawalRules" :bankAll="bankAll || {}" :userWithdrawalAmount="userWithdrawalAmount"
          :wtRnum="wtRnum" :wtMinimum="wtMinimum" :wtHighest="wtHighest" @submit="ConfirmWithdrawal"
          :selectWithdrawalChannels="selectWithdrawalChannels" v-if="withdrawalSteps == 1 && $store.state.isWallet"
          @back="back" :hide="hide">
        </withdrawalTwo>
      </div>



    </el-dialog>



    <el-dialog :modal-append-to-body="false" :visible.sync="eduDialogVisible"
      custom-class="el-dialog-transparent el-dialog-center el-dialog-edu " :modal="$store.state.windowWidth > 555">
      <i class="el-icon-error el-icon " :class="!linkTypeShow ? 'icon-error-left' : ''" @click="vhide"></i>
      <div class="v-center">
        <template v-if="showLazyYoutube && linkTypeShow">
          <LazyYoutube ref="LazyVideo" :showTitle="false" :src="videoLink" max-width="700px" thumbnail-format="jpeg"
            :aspect-ratio="isMobileFlag ? '45:77' : '3:2'" />
        </template>
        <template v-else>
          <iframe class="js-iframe" :src="videoLink" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        </template>
      </div>
    </el-dialog>
    <tips ref="tips"></tips>

  </div>
</template>

<script>
/**
 * header钱包弹窗组件，包含充值和提现，在header里面引用
 */
import myTabs from "@/components/myTabs.vue";
import digitalCurrencyRecharge from './digitalCurrencyRecharge.vue'
import legalCurrencyRecharge from './legalCurrencyRecharge.vue'
import rechargeTwo from './rechargeTwo.vue'
import tips from './tips.vue'
import withdrawalTwo from './withdrawalTwo.vue'
import digitalCurrencyWthdrawal from './digitalCurrencyWthdrawal.vue'
import legalCurrencyWithdrawal from './legalCurrencyWithdrawal.vue'
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      selectRechargeChannel: null,//当前选中充值渠道
      selectWithdrawalChannels: null,
      tabsCur: 0,//顶部充值提现切换tabs下标
      tabsCur2: 1,//顶部数字钱包，法定货币切换tabs下标
      rechargeSteps: 0,//充值步数
      withdrawalSteps: 0,//充值步数
      isLoading: false,
      videoLink: "",
      eduDialogVisible: false,
      showLazyYoutube: false,
      linkTypeShow: true,
      methodId: null,
      channelList: [],
      bankId: null
    };
  },
  props: {
    pay_passwd_verify: {
      default: 0
    },
    ver_withdrawal_first: {
      default: 0
    },
    withdrawalRules: {
      default: 0
    },
    userWithdrawalAmount: {
      default: 0
    },
    wtRnum: {
      default: 0
    },
    wtMinimum: {
      default: 0
    },
    wtHighest: {
      default: 0
    },
    wtAllBank: {
      default: () => []
    },
    wtAllUsdt: {
      default: () => []
    },
    bankAll: {
      default: null
    },
    rechargeList: {
      default: () => []
    },
    allUsdt: {
      default: () => []
    },
    dep_address: {
      default: () => ""
    },
    allBank: {
      default: () => []
    }
  },
  watch: {
    // 格式化提现默认选择
    tabsCur(v) {
      if (v == 1) {
        if (this.wtAllBank.length) {
          this.tabsCur2 = 1
        } else if (this.wtAllUsdt.length) {
          this.tabsCur2 = 0
        }
      }
    },
    bankAll(v) {
      if (v && v.pay_types.length != 0) {
        this.selectMethod(v.pay_types[0])
      }
    }
  },
  computed: {
    rechargeImage() {
      if (!this.bankAll) return
      const imgs = this.bankAll.recharge_action_reg_json.filter(item => item.recharge_image)
      if (imgs.length == 0) {
        return
      } else {
        return imgs[0].recharge_image
      }
    },
    /**
     * 数字钱包列表 根据rechargeList筛选，"uname": "usdt" 为数字钱包
     * */
    digitalWallet() {
      return this.rechargeList.filter(item => item.uname == "usdt")
    },

    /**
     * 法定货币列表 根据rechargeList筛选，是银行卡得都归类法定货币"uname": "bank" 为银行卡
     * */
    FiatCurrency() {
      return this.rechargeList.filter(item => item.uname == "bank")
    }
  },
  components: { tips, myTabs, digitalCurrencyRecharge, legalCurrencyRecharge, rechargeTwo, digitalCurrencyWthdrawal, legalCurrencyWithdrawal, withdrawalTwo },
  methods: {
    slideChange() {
      this.secondStepOfLegalTenderWithdrawal([...this.wtAllUsdt , ...this.wtAllBank][this.$refs.swiperRef.swiper.activeIndex])
    },
    nextStep() {
      this.rechargeSteps = 1
    },
    selectMethod(item) {
      this.rechargeSteps = 0
      this.methodId = item.id
      this.channelList = item.pay_channel
    },
    //视频弹出
    handleCustomEvent(value) {
      this.videoLink = value
      this.eduDialogVisible = true
      this.showLazyYoutube = true
      this.$nextTick(() => {
        this.$refs.LazyVideo.playVideo()
      })

    },
    parentEvent(value) {
      this.videoLink = value
      this.eduDialogVisible = true
      this.linkTypeShow = false
    },
    setting() {
      this.$emit("setting")
      this.hide()
    },
    goJl() {
      this.hide()
      this.goUrl('/transactions')
    },

    ConfirmWithdrawal({ id, price, phone, code, withdrawalPass }) {
      this.$emit("ConfirmWithdrawal", "", id, price, phone, code, withdrawalPass)
    },
    /**
     * 返回第一步
     * */
    back() {
      this.rechargeSteps = 0
      this.withdrawalSteps = 0
      this.$refs.wallet_right.scrollTop = 0;
    },

    /**
     * 法定货币充值第二步
     * @param {Object} rechargeChannels 充值渠道
     * */
    secondStepOfLegalTenderRecharge(rechargeChannels) {
      this.rechargeSteps = 1
      this.selectRechargeChannel = rechargeChannels
    },

    /**
     * 法定货币提现第二步
     * @param {Object} withdrawalChannels 提现渠道
     * */
    secondStepOfLegalTenderWithdrawal(withdrawalChannels) {
      // pay_passwd_verify验证没有开启直接过，或者已经设置了提现密码，验证通过
      if (this.$store.state.userInfo && this.$store.state.userInfo.withdrawal_psd || this.pay_passwd_verify === 0) {
        // this.withdrawalSteps = 1
        this.bankId = withdrawalChannels.id
        this.selectWithdrawalChannels = withdrawalChannels
      } else {
        this.setting()
      }

    },

    closeWallet() {
      setTimeout(() => {
        this.back()
        this.tabsCur = 0
      }, 500)
    },
    hide() {
      this.$store.commit("setIsWallet", false)
    },
    async hideSync() {
      const giftAmount = this.$refs.rechargeTwo?.giftAmount
      if (!giftAmount) {
        this.hide()
        return
      }
      const confirm = await this.$refs.tips.show(giftAmount)
      if (confirm) {
        this.hide()
      }
    },

    /**
     * 钱包弹窗显示
     * */
    show() {
      this.$store.commit("setIsWallet", true)
      // this.$store.state.isWallet = true
    },

    /**
     * 顶部充值提现切换tabs下标
     * @param {Number} index 需要改变的索引 
     * */
    changeTabs(index) {
      this.tabsCur = index
      const walletRightElement = this.$refs.wallet_wapper;
      walletRightElement.scrollTop = 0
      if (index == 1) {
        if (this.wtAllBank.length != 0) {
          this.secondStepOfLegalTenderWithdrawal(this.wtAllBank[0])
        }
        if (this.wtAllUsdt.length != 0) {
          this.secondStepOfLegalTenderWithdrawal(this.wtAllUsdt[0])
        }
      } else {
        this.selectMethod(this.bankAll.pay_types[0])
      }
    },

    /**
     * 顶部数字钱包，法定货币切换tabs下标
     * @param {Number} index 需要改变的索引 
     * */
    changeTabs2(index) {
      this.tabsCur2 = index
    },
    vhide() {
      if (this.$refs.LazyVideo) {
        this.$refs.LazyVideo.stopVideo()
      }
      this.videoLink = ""
      this.eduDialogVisible = false
      this.showLazyYoutube = false
    },
  },
  // mounted(){
  //   if(this.bankAll&&this.bankAll.pay_types.length!=0){
  //     console.log('aa');
  //     this.methodId = bankAll.pay_types[0].id
  //     this.channelList = bankAll.pay_types[0].pay_channel
  //   }
  // }
};
</script>

<style scoped lang="less" src="@/assets/css/wallet.less"></style>
