<template>
  <div class="inputs">
    <div :class="['input-item',
    index==value.length?'input-item-active':'',
    value[index]?'input-placehoder':'']" v-for="item,index in 6" :key="index">
    </div>
    <input class="input" inputmode="numeric" @input="input" ref="input">
  </div>
</template>

<script>
export default {
  data(){
    return {
      value:""
    }
  },
  props:{
    model:{
      default:""
    }
  },
  watch:{
    model(v){
      this.$refs.input.value = v
      this.value = v
    }
  },
  model:{
    prop:"model",
    event:"change"
  },
  methods:{
    input(e){
      if(/^[0-9]{0,6}$/.test(e.target.value)) {
        this.value = e.target.value
        this.$emit("change",this.value)
      }else{
        this.$refs.input.value = this.value
      }
    }
  }
}
</script>

<style scoped lang="less">
.inputs{
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  background-color: #161f2c;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid #2a3546;
  color: #FFF;
  transition: all .2s;
  &:focus-within {
    // border: 2px solid #045d4b;
    .input-item-active::after{
      display: block;
      content: "";
      width: 1px;
      height: 18px;
      background-color: #fff;
      animation: active 1s infinite linear;
    }
  }
}
.input{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  opacity: 0;
}

.input-item{
  width: 40px;
  height: 40px;
  border-left: 2px solid #2a3546;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  &.input-placehoder::after{
    display: block;
    content: "";
    width: 5px;
    height: 5px;
    background-color: #fff;
    border-radius: 50%;
  }
  &:first-child{
    border: none;
  }
}
@keyframes active {
  0%{
    opacity: 1;
  }50%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
</style>