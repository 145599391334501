<template>
  <div class="rules" :class="{'rules--error':error.length}" ref="rules">
    <slot></slot>
    <div class="rules-error">
      <div v-for="item,index in error" :key="index">{{ $t(item.msg) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error:[]
    }
  },
  props: {
    value: {
      default: ""
    },
    rules: {
      default: () => []
    }
  },
  watch:{
    value(){
      this.validation()
    }
  },
  methods: {
    validation(){
      this.error = []
      this.rules.forEach(item => {
        if(item.reg && !item.reg.test(this.value)) this.error.push(item)
      });
      return !this.error.length
    },
    clear(){
      this.error = []
    }
  },
  mounted() {
    const input = this.$refs.rules.querySelector("input")
    input.addEventListener("focus", () => {
      this.validation()
    })
    input.addEventListener("blur", () => {})
  }
}
</script>

<style scoped lang="less" src="@/assets/css/rules.less"></style>