<template>
  <div class="recharge-two-wapper">
    <div class="title-wapper">
      <!-- <i class="el-icon el-icon-back"></i> -->
      <span v-if="selectWithdrawalChannels">{{ $t("提现") }} - {{ selectWithdrawalChannels.bank_name }}({{ selectWithdrawalChannels.bank_number.slice(-4)
        }})</span>
        <span v-else>{{ $t("提现") }} </span>
    </div>

    <div class="deposit_info_container">
    <div class="balance">
      <span>{{ $t("余额") }}</span>
      <span>{{ $helper.get("rule").currency.symbol }} {{ numFormat($store.state.userInfo.balance1) }}</span>
    </div>
    <div class="num-input-wapper" v-if="withdrawalRules == 1">
      <span>{{ $t("可提现余额") }}</span>
      <span>{{ $helper.get("rule").currency.symbol }} {{ numFormat(userWithdrawalAmount) }}</span>
    </div>
    <div class="input-wapper">
      <el-input class="input" type="number"  autocomplete="off"  :placeholder="$t('请输入提现金额')" v-model="value" @input="onInput">
        <span class="prefix-icon" slot="prefix">{{ dw }}</span>
      </el-input>
    </div>
    
    <div class="input-wapper" v-if="ver_withdrawal_first == 1">
      <el-input class="input qhClass-input" type="number" :placeholder="$t('请输入手机号码')" v-model="phone">
        <div slot="prefix">
          <el-select size="small" v-model="selecQu" :placeholder="$t('请选择')" class="qhClass">
            <el-option v-for="(item,index) in idc" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
      </el-input>
    </div>
    <div class="input-wapper" v-if="ver_withdrawal_first == 1">
      <el-input v-model="code" :placeholder="$t('输入你的验证码')" auto-complete="new-password">
        <template slot="suffix">
          <el-button type="primary" class="button_blue" @click="sendPhoneCode" :disabled="!!timeId"
            :loading="bthLoading">
            {{ $t(btnText) }}
          </el-button>
        </template>
      </el-input>
    </div>
    <div class="input-pass" v-if="pay_passwd_verify==1">
      <div class="input-title">{{ $t("输入密码") }}</div>
      <payPasswordInput v-model="withdrawalPass"></payPasswordInput>
    </div>
    <div class="deposit_btn_wrap">
        <el-button class="deposit_btn button_red" @click="submit" :loading="$store.state.bottonLoading" :disabled="!selectWithdrawalChannels">
          <div class="btn-content">
            <span>{{ $t("提现") }}</span>
            <div v-if="selectWithdrawalChannels">
              <span >{{ calc(value) }}</span>
              <span class="btn-dw">{{ selectWithdrawalChannels.currency_code }}</span>

              <i class="el-icon el-icon-arrow-right"></i>
            </div>
          </div>
        </el-button>
      </div>
    <div class="tip" v-if="selectWithdrawalChannels">
      <div class="tip-title">{{ $t("提示") }}</div>
      <div class="tip-item">{{ $t("单日剩余提现次数") }} {{ wtRnum }}</div>
      <div class="tip-item">{{ $t("最低提现金额为") }} {{ wtMinimum }} {{ bankAll.currency_code }}</div>
      <div class="tip-item">{{ $t("最高提现金额为") }} {{ wtHighest }} {{ bankAll.currency_code }}</div>
      <div class="tip-item" v-if="bankAll.currency_code != selectWithdrawalChannels.currency_code">{{ $t("今日汇率") }}：1{{
        bankAll.currency_code }}={{ numFormat(selectWithdrawalChannels.withdrawal_rate)
        }}{{ selectWithdrawalChannels.currency_code }}</div>
    </div>
    <!-- <div class="sxf-box">
      <div class="sxf-wapper">
        <span>{{ $t("手续费") }}：</span>
        <span>R${{ 0 }}</span>
      </div>
    </div> -->

    
    <safe></safe>
  </div>
  </div>
</template>

<script>
import svgIcon from "@/components/svg.vue";
import payPasswordInput from "@/components/payPasswordInput.vue";
import safe from './safe.vue'
import { sendcode_index_api } from "@/api/user";
export default {
  data() {
    return {
      numList: [50, 100, 200, 500, 1000, 2000, 5000, 10000],
      value: "",
      phone: "",
      code: "",
      idc: [],
      selecQu: "55",
      btnText: "发送验证码",
      bthLoading: false,
      timeId: null,
      withdrawalPass: ''
    }
  },
  components: { safe, svgIcon ,payPasswordInput },
  props: {
    ver_withdrawal_first: {
      default: 0
    },
    pay_passwd_verify: {
      default: 0
    },
    withdrawalRules: {
      default: 0
    },
    userWithdrawalAmount: {
      default: 0
    },
    wtRnum: {
      default: 0
    },
    wtMinimum: {
      default: 0
    },
    wtHighest: {
      default: 0
    },
    selectWithdrawalChannels: {
      default: () => { }
    },
    bankAll: {
      default: () => { }
    }
  },
  computed: {
    dw() {
      return this.bankAll.currency_symbol
    },
  },
  methods: {
    onInput(e) {
      this.value = this.clearIntegerNum(e)
    },
    calc(num) {
      return this.numFormat(num * this.selectWithdrawalChannels.withdrawal_rate , false , 2 , 6)
    },
    back() {
      this.$emit("back")
    },
    submit() {
      if (!this.value) {
        this.$message({
          showClose: true,
          message: this.$t("请输入提现金额"),
          type: 'error'
        });
        return
      }
      this.$confirm(this.$t('如果您在未完成奖金流水要求的情况下进行提款操作，您的奖金额度将会被清除。感谢您的理解与合作。'), this.$t('请注意'), {
        confirmButtonText: this.$t('确定提现'),
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$emit("submit", {
          id: this.selectWithdrawalChannels.id,
          price: this.value,
          phone: this.phone,
          code: this.code,
          withdrawalPass:this.withdrawalPass
        })
      });
    },
    // 获取手机验证码
    async sendPhoneCode() {
      if (!this.phone) {
        this.errorTips("手机不能为空");
        return;
      }
      this.bthLoading = true
      const res = await sendcode_index_api({
        qh: this.selecQu,
        phone: this.phone,
        scene: 'verify'
      })
      this.bthLoading = false
      if (res && res.data.code == 1) {
        this.sendSucces()
        this.successTips(res.data.msg);
      } else {
        this.errorTips(res.data.msg);
      }
    },
    sendSucces() {
      this.btnText = "60s"
      this.timeId = setInterval(() => {
        const num = parseInt(this.btnText)
        if (num == 0) {
          clearInterval(this.timeId)
          this.timeId = null
          this.btnText = "发送验证码"
          return
        }
        this.btnText = `${num - 1}s`
      }, 1000)
    }
  },
  mounted() {
    this.idc = this.$helper.get("idc")
    this.selecQu = this.idc[0].value
  },
  beforeDestroy() {
    this.timeId && clearInterval(this.timeId)
  }
};
</script>

<style scoped lang="less">
.recharge-two-wapper {
  position: relative;
  border-radius: 10px;
  color: #fff;
  padding: 16px 16px 80px;
  overflow: hidden;
  height: 100%;
  @media (max-width:768px) {
    height:auto;
    padding: 16px 16px 80px;
  }
}

.deposit_info_container{
  padding-bottom: 90px;
  height: 100%;
  overflow-y: auto;
}

.title-wapper {
  padding: 4px 0;
  margin-bottom: 16px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width:768px) {
    margin-bottom: 10px;
    gap: 4px;
  }
}

.tips_icon {
  width: 5px;
  height: 16px;
  background-color: #3AD43D;
  @media (max-width:768px) {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}

.btn ::v-deep .el-icon-loading {
  position: relative;
  left: 16px;
  top: 4px;
}

.el-icon-back {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translate(-10px, -50%);
  font-size: 20px;
  margin-bottom: 54px;
}

.input-wapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

/* .input {
  width: 254px;
} */
.input2 {
  width: 135px;
}

.el-icon-d-arrow-right {
  font-size: 20px;
}

.prefix-icon {
  transform: translate(-5px, 10px);
  color: #fff;
  line-height: 44px;
}

.input-label {
  margin-bottom: 14px;
  font-size: 14px;
}

.num-item {
  width: 99px;
  height: 39px;
  line-height: 39px;
  background-color: #003026;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 13px;
  cursor: pointer;
}

.num-item:hover {
  background-color: #3a495f;
}

.num-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 35px;
}

.btn {
  padding: 18px 0;
  overflow: hidden;
}

.btn-content {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  font-size: 16px;
  gap: 16px;
  @media (max-width:768px) {
    width: 100%;
  }
}

.btn-dw {
  margin-left: 10px;
}

.el-icon-arrow-right {
  margin-left: 4px;
}

.tip-item {
  color: #fff;
  margin-left: 19px;
  font-size: 12px;
  margin-bottom: 8px;
}

.tip-title {
  margin-top: 19px;
  margin-bottom: 11px;
  font-size: 18px;
}

.tip {
  margin-bottom: 30px;
}

.vdn-icon {
  width: 30px;
  margin-right: 4px;
}

.num-input-wapper {
  height: 44px;
  background: #161f2c;
  border-radius: 5px;
  border: 2px solid #2a3546;
  margin-bottom: 22px;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.num-input-wapper span:first-child {
  flex: 1;
}

.sxf-box {
  display: flex;
  justify-content: center;
  margin-bottom: 22px;
}

.sxf-wapper {
  border: 2px solid #223145;
  border-radius: 11px;
  padding: 4px 24px;
  color: #829CC5;
}

.balance {
  display: flex;
  justify-content: space-between;
  color: #FFFFFF;
  padding: 0 16px;
  margin-bottom: 12px;
  font-size: 12px;
}

.sxf-wapper span:last-child {
  color: #4CD964;
}

.btn {
  margin-bottom: 62px;
}



.qhClass-input {
  >::v-deep(.el-input__inner) {
    padding-left: 90px;
  }
}

.qhClass {
  height: 44px;
  width: 80px;
  display: flex;
  align-items: center;
  border: none;
  margin-left: -12px;

  ::v-deep .el-input__inner {
    border: none !important;
    background-color: transparent;
    padding-left: 14px;
  }
}

::v-deep .is-disabled {
  color: #cbcbcb;
  background: #2a3546 !important;
}
.input-pass{
  margin-bottom: 16px;
}
.input-title{
  margin-bottom: 10px;
  color: #FFFFFF;
}

.deposit_btn_wrap{
  position: absolute;
  width: 100%;
  height: 72px;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: #3a495f;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  gap: 16px;
  @media (max-width:768px) {
    position: relative;
    flex-direction: column;
    background: transparent;
    height: auto;
    padding-right:0;
  }
}

.deposit_btn{
  border-radius: 7px;
  margin-left: 0 !important;
  @media (max-width:768px) {
    width: 100%;
    margin-bottom: 30px;
    .btn-content{
      display: flex;
      justify-content: center
    }
  }
}

::-webkit-scrollbar {
  width: 0
}
</style>
